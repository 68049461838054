import React from 'react'
import { graphql } from 'gatsby'
import LocatableDetail from "../components/locatable-detail"

export default ({ data }) => (
  <LocatableDetail locatable={data.locatable} settings={data.datoCmsWebsiteLayout}/>
)

export const query = graphql`
  query DealQuery($id: String!) {
    datoCmsWebsiteLayout {
      sponsors {
        ...Sponsor
      }
    }
    locatable: datoCmsDeal(id: { eq: $id }) {
      title
      subtitle
      summary
      description
      requiresTicket
      presaleStart
      presaleEnd
      saleStart
      showareUrl
      saleEnd
      heroImage {
        url
        fluid(maxWidth: 2800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      subcategories {
        title
        heroTitleBlock {
          ...HeroTitleBlock
        }
        sponsors {
          ...Sponsor
        }
        landingPageDiscoverMore {
          ...DiscoverMore
        }
      }
      sponsors {
        ...Sponsor
      }
      itemSponsors {
        ...Sponsor
      }
      model {
        apiKey
      }
      pageHeader {
        ...HeroTitleBlock
      }
    }
  }
`
